import React from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "src/components/PrivateRoute";
import LoginPage from "src/pages/LoginPage/LoginPage";
import GamePage from "src/pages/GamePage/GamePage";
import AdminPage from "src/pages/AdminPage/AdminPage";

const Router = () => {
  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route element={<AdminPage />} path="/admin-panel" />
      </Route>
      <Route path="/" element={<GamePage />} />
      <Route path="/admin-login" element={<LoginPage />} />
    </Routes>
  );
};

export default Router;
