import { nanoid } from "nanoid";
import { useEffect, useState } from "react";

export const randomInRange = (startNumber, endNumber) => {
  return Math.random() * (endNumber - startNumber) + startNumber;
};

export const groupBy = (array, fn) => {
  const result = [];
  let group = [array[0]];
  for (let i = 1; i < array.length; i++) {
    if (fn(array[i - 1], array[i])) {
      group.push(array[i]);
    } else {
      result.push(group);
      group = [array[i]];
    }
  }
  result.push(group);

  return result;
};

const parseLetters = (word) => {
  const groups = groupBy(
    word.split(""),
    (_, next) => /[0-9]/.test(next) || next === " "
  );

  return groups.map((group) => {
    const hasSpace = !!group.find((char) => char === " ");
    const color = group.slice(1).join("").trim();

    return {
      id: nanoid(),
      correctLetter: group[0],
      typedLetter: "",
      color: color ? parseInt(color) : undefined,
      hasSeparator: hasSpace,
    };
  });
};

export const parsePuzzle = (puzzle) => {
  return puzzle.map(({ word, hint }) => ({
    letters: parseLetters(word),
    hint,
    id: nanoid(),
  }));
};

export const triesPerMode = { standard: 5, advanced: 3 };

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};
