import Tooltip from "src/components/Tooltip";
import { default as HintIcon } from "src/assets/hint_icon.svg";
import { useWindowSize } from "src/utils/utils";

const TextHint = ({ hint }) => {
  return <div style={{ transform: "translate(100%, -3px)" }}>{hint}</div>;
};

const TooltipHint = ({ hint }) => {
  return (
    <Tooltip content={hint} direction="left">
      <div className="hints-wrapper-row">
        <img className="Sort-icon" src={HintIcon} alt="" />
      </div>
    </Tooltip>
  );
};

const Hint = ({ hint, isPreview }) => {
  const windowSize = useWindowSize();

  return windowSize.width >= 1024 || isPreview ? (
    <TextHint hint={hint} />
  ) : (
    <TooltipHint hint={hint} />
  );
};

export default Hint;
