import React, { useState, useRef, useMemo } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { usePuzzles } from "src/utils/api";
import "./AdminPage.scss";
import Board from "src/components/Board";
import { parsePuzzle } from "src/utils/utils";
import "src/styles.scss";

const validatePuzzle = (puzzle) => {
  if (!puzzle.words) {
    return { reason: 'Puzzle is missing "words"' };
  }

  if (!puzzle.schedule) {
    return { reason: 'Puzzle is missing "schedule"' };
  }

  const missingWordIndex = puzzle.words.findIndex(
    (puzzle) => !puzzle.hasOwnProperty("word")
  );

  if (missingWordIndex !== -1) {
    return {
      reason: `Puzzle is missing "word" at index ${missingWordIndex}`,
    };
  }
};

const useValidatedPuzzles = () => {
  const { loading, puzzles } = usePuzzles();

  const validatedPuzzles = useMemo(() => {
    if (!puzzles) {
      return puzzles;
    }

    return puzzles.map((puzzle) => ({
      ...puzzle,
      invalidError: validatePuzzle(puzzle),
    }));
  }, [puzzles]);

  return { loading, puzzles: validatedPuzzles };
};

const AdminPage = () => {
  const { logout } = useAuth();
  const { loading, puzzles } = useValidatedPuzzles();

  const [selectedPuzzle, setSelectedPuzzle] = useState(null);
  const dropdownRef = useRef();

  const board =
    selectedPuzzle && !selectedPuzzle.invalidError
      ? parsePuzzle(selectedPuzzle.words)
      : null;

  const onSelectPuzzle = (e) => {
    const currentPuzzle = puzzles[e.target.value];
    if (currentPuzzle?.words) {
      setSelectedPuzzle(currentPuzzle);
    } else {
      setSelectedPuzzle(null);
    }
  };

  const renderSelectPuzzleMessage = () => {
    return (
      <div className="admin-container-board-wrapper">
        <h1>Please select puzzle</h1>
      </div>
    );
  };

  const renderPuzzlePreview = () => {
    return !selectedPuzzle.invalidError ? (
      <div className="admin-container-board-wrapper">
        <Board isPreview center board={board} />
      </div>
    ) : (
      <div className="admin-container-board-wrapper">
        <div>
          <h1>Puzzle is incorrect. Reason:</h1>
          <p>{selectedPuzzle.invalidError.reason}</p>
        </div>
      </div>
    );
  };

  const puzzlesPreview = () => {
    return selectedPuzzle ? renderPuzzlePreview() : renderSelectPuzzleMessage();
  };

  const renderConditionPage = (text) => {
    return (
      <div className="admin-container">
        <h1>{text}</h1>
      </div>
    );
  };

  if (loading) return renderConditionPage("Loading...");

  return (
    <div className="admin-container">
      <div className="admin-container-logout-wrapper">
        <button onClick={logout}>Log out</button>
      </div>
      <div className="admin-container-wrapper">
        <h1>Puzzle Preview</h1>
        <select
          defaultValue={selectedPuzzle}
          onChange={onSelectPuzzle}
          ref={dropdownRef}
        >
          <option value={null}>None</option>
          {puzzles &&
            puzzles.map((puzzle, index) => {
              return (
                <option value={index} key={index}>
                  {puzzle.invalidError && "[INVALID] "}Scheduled for{" "}
                  {puzzle.schedule.toLocaleString()} [ID: {puzzle.id}]
                </option>
              );
            })}
        </select>
      </div>
      {puzzlesPreview()}
    </div>
  );
};

export default AdminPage;
