import { ReactComponent as SpireOne } from "../assets/spire-one.svg";
import { ReactComponent as SpireTwo } from "../assets/spire-two.svg";
import { ReactComponent as SpireThree } from "../assets/spire-three.svg";
import { ReactComponent as SpireFive } from "../assets/spire-five.svg";
import { ReactComponent as SpireOneLarge } from "../assets/spire-one-large.svg";
import { ReactComponent as SpireTwoLarge } from "../assets/spire-two-large.svg";
import { ReactComponent as SpireThreeLarge } from "../assets/spire-three-large.svg";
import { ReactComponent as SpireFourLarge } from "../assets/spire-four-large.svg";
import { randomInRange } from "src/utils/utils";
import { forwardRef } from "react";
import "./Spire.scss";

const selectedSpire = Math.floor(randomInRange(0, 4));

const Spire = forwardRef(({ isBig }, ref) => {
  const spiresSmall = [
    <SpireOne />,
    <SpireTwo />,
    <SpireThree />,
    <SpireFive />,
  ];

  const spiresBig = [
    <SpireOneLarge width="100px" height="200px" />,
    <SpireTwoLarge width="100px" height="200px" />,
    <SpireThreeLarge width="100px" height="200px" />,
    <SpireFourLarge width="100px" height="200px" />,
  ];

  const spires = isBig ? spiresBig : spiresSmall;

  return (
    <div ref={ref} className="spire">
      {spires[selectedSpire]}
    </div>
  );
});

export default Spire;
