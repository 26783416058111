import "./GameEndModal.scss";

const GameEndModal = ({ gameState, tries, onRestart }) => {
  const triesStr = tries + 1 === 1 ? "try" : "tries";

  return (
    <div className="game-end-modal">
      <div className="game-end-content">
        {gameState === "won" && (
          <div>
            <h1>Congratulations!</h1>
            <p>
              You have completed WordBuilding in {tries + 1} {triesStr}.
            </p>
            <p>Would you like to restart?</p>
            <button
              className="modal-buttons-container-left-button"
              style={{ margin: 0 }}
              onClick={onRestart}
            >
              Try Again!
            </button>
          </div>
        )}

        {gameState === "lost" && (
          <div>
            <h1>Oh no!</h1>
            <p>Better luck next time!</p>
            <button
              className="modal-buttons-container-left-button"
              style={{ margin: 0 }}
              onClick={onRestart}
            >
              Try Again!
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default GameEndModal;
