import React from "react";
import { default as QuestionMark } from "src/assets/question_mark.svg";
import { default as ReloadIcon } from "src/assets/reload_icon.svg";
import { triesPerMode } from "src/utils/utils";
import "./Navbar.scss";

const Life = ({ max, tries }) => {
  return (
    <div className="navbar-container-marks-wrapper-tries-wrapper">
      {[...Array(max - tries)].map((_, i) => (
        <div
          key={`left-${i}`}
          className="navbar-container-marks-wrapper-tries-wrapper-item"
        />
      ))}
      {[...Array(tries)].map((_, i) => (
        <div
          key={`try-${i}`}
          className="navbar-container-marks-wrapper-tries-wrapper-empty-item"
        />
      ))}
    </div>
  );
};

const Navbar = ({ tries, mode, onRestart, onHelp }) => {
  return (
    <div className="navbar-container">
      <div className="navbar-container-wrapper">
        <div className="navbar-container-start-position">
          <h1>Towword</h1>
          <div className="navbar-container-author">By Al|Orin</div>
        </div>
        <div className="navbar-container-marks-wrapper">
          {mode !== "unknown" && (
            <Life max={triesPerMode[mode]} tries={tries} />
          )}
          <div
            onClick={onRestart}
            className="navbar-container-marks-wrapper-reload-mark"
          >
            <img src={ReloadIcon} alt="restart" />
          </div>
          <div
            className="navbar-container-marks-wrapper-question-mark"
            onClick={onHelp}
          >
            <img src={QuestionMark} alt="help" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
