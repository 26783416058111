import React from "react";
import "./StartModal.scss";

const StartModal = ({ onStandard, onAdvanced }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-wrapper">
        <div className="modal">
          <div className="modal-header">
            <h1>Welcome to Towword!</h1>
          </div>
          <div className="modal-paragraph">
            <span>
              You can read the game rules by clicking on <b>?</b> in the
              top-right corner
            </span>
          </div>
          <div className="modal-game-selector">
            <span>Please select a game mode</span>
          </div>
          <div className="modal-buttons-container">
            <button
              autoFocus
              className="modal-buttons-container-left-button"
              onClick={onStandard}
            >
              Standard
            </button>
            <button
              className="modal-buttons-container-right-button"
              onClick={onAdvanced}
            >
              Advanced
            </button>
          </div>
          <div className="modal-rules-container">
            <div className="modal-rules-container-item">
              <span>5 chances to fill the puzzle</span>
              <span className="modal-rules-container-item-mark">-</span>
              <span>check if your answers are correct</span>
            </div>
            <div className="modal-rules-container-item">
              <span>3 chances to fill the puzzle</span>
              <span className="modal-rules-container-item-mark">-</span>
              <span>no possibility to check if your answers are correct</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartModal;
