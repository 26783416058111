import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import "firebase/auth";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAKrOTpvl0CQE0aJlO8Qr3gkkk2YkSps3U",
  authDomain: "wordstruction.firebaseapp.com",
  projectId: "wordstruction",
  storageBucket: "wordstruction.appspot.com",
  messagingSenderId: "286129847046",
  appId: "1:286129847046:web:b51510bd3a2c72c914bc87",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);

export default app;
