import classNames from "classnames";
import "./VirtualKeyboard.scss";

const keyRows = ["QWERTYUIOP", "ASDFGHJKL", "ZXCVBNM"];

const Key = ({
  children,
  onClick,
  green = false,
  big = false,
  disabled = false,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={classNames("virtual-keyboard-key", {
        "virtual-keyboard-key-big": big,
        "virtual-keyboard-key-green": green,
      })}
    >
      {children}
    </button>
  );
};

const DeleteKey = ({ onClick }) => {
  return (
    <Key onClick={onClick} big>
      Del
    </Key>
  );
};

const submitLabel = {
  restart: "New Game",
  tryAgainAdvanced: "Retry",
};

const SubmitKey = ({ mode, onClick }) => {
  return (
    <Key onClick={onClick} disabled={mode === "disabled"} green big>
      {submitLabel[mode] ?? "Check Answer"}
    </Key>
  );
};

const HalfKey = () => {
  return <div className="virtual-keyboard-key-half"></div>;
};

const VirtualKeyboard = ({ submitMode, onSubmit, onKeyPress, onDelete }) => {
  return (
    <div className="virtual-keyboard">
      {keyRows.map((row, rowIndex) => (
        <div className="virtual-keyboard-row" key={row}>
          {rowIndex === 1 && <HalfKey />}
          {rowIndex === 2 && <SubmitKey mode={submitMode} onClick={onSubmit} />}
          {row.split("").map((key) => (
            <Key key={key} onClick={() => onKeyPress(key)}>
              {key}
            </Key>
          ))}
          {rowIndex === 1 && <HalfKey />}
          {rowIndex === 2 && <DeleteKey onClick={onDelete} />}
        </div>
      ))}
    </div>
  );
};

export default VirtualKeyboard;
