import classNames from "classnames";
import "./Letter.scss";

const Letter = ({ letter, color, isSelected, isLast, isInvalid, onClick }) => {
  return (
    <div
      className={classNames("letter", {
        [`colored-letter-${color}`]: color,
        "letter-current": isSelected,
        "letter-last": isLast,
        incorrect: isInvalid,
      })}
      onClick={onClick}
    >
      {letter?.toUpperCase()}
    </div>
  );
};

export default Letter;
