import "./CheckAnswer.scss";

const submitLabel = {
  restart: "New Game",
  tryAgainAdvanced: "Retry",
};

const CheckAnswer = ({ submitMode, onSubmit }) => {
  return (
    <div className="check-answer-wrap">
      <button
        className="check-answer"
        disabled={submitMode === "disabled"}
        onClick={onSubmit}
      >
        {submitLabel[submitMode] ?? "Check Your Answer"}
      </button>
    </div>
  );
};

export default CheckAnswer;
