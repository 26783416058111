import React, { useLayoutEffect, useRef } from "react";
import Letter from "src/components/Letter";
import Spire from "src/components/Spire";
import "./Board.scss";
import Hint from "./Hint";

const Board = ({
  board,
  cursor,
  center,
  isValidating,
  onFocusLetter,
  isPreview = false,
}) => {
  const spireRef = useRef();

  useLayoutEffect(() => {
    spireRef.current.scrollIntoView({ inline: "center", block: "start" });
    window.scrollTo(undefined, 0);
  }, []);

  return (
    <div className="game-wrapper">
      <div className="board-wrapper">
        <div
          className="board"
          style={{
            margin: center ? "0 auto" : 0,
          }}
        >
          <div className="board-lines">
            <Spire ref={spireRef} isBig={board[0].letters.length > 1} />
            {board.map((line, rowIndex) => (
              <div
                key={line.id}
                className="board-line"
                style={{
                  paddingBottom: rowIndex === board.length - 1 ? "12px" : 0,
                }}
              >
                {line &&
                  line.letters.map((letter, colIndex) => (
                    <React.Fragment key={letter.id}>
                      <Letter
                        letter={
                          isPreview ? letter.correctLetter : letter.typedLetter
                        }
                        color={letter.color}
                        isInvalid={
                          !isPreview &&
                          isValidating &&
                          letter.typedLetter !== letter.correctLetter
                        }
                        isLast={rowIndex === board.length - 1}
                        isSelected={
                          cursor?.row === rowIndex && cursor?.col === colIndex
                        }
                        onClick={() => onFocusLetter?.(rowIndex, colIndex)}
                      />
                      {letter.hasSeparator && <div className="mark"></div>}
                    </React.Fragment>
                  ))}
                <div
                  style={{
                    position: "absolute",
                    right: "-36px",
                    bottom: "11px",
                  }}
                >
                  {line.hint && <Hint isPreview={isPreview} hint={line.hint} />}
                </div>
              </div>
            ))}
            {Array.from(Array(3)).map((_, i) => (
              <div
                key={i}
                style={{
                  width: `calc(100% + ${(i + 1) * 40}px)`,
                  height: "12px",
                  border: "2px solid var(--division-marks-color)",
                  transform: `translateY(-${2 * i}px)`,
                }}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Board;
