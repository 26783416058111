import produce from "immer";
import { parsePuzzle, triesPerMode } from "src/utils/utils";

const reducer = {
  startGame: (_, payload) => {
    return {
      gameState: "running", // running, lost, won
      isValidating: false,
      isShowingHelp: false,
      cursor: {
        row: 0,
        col: 0,
      },
      mode: payload.mode, // unknown, standard, advanced
      tries: 0,
      board: parsePuzzle(payload.puzzle),
    };
  },
  restart: (_, payload) => {
    return {
      gameState: "running", // running, lost, won
      isValidating: false,
      isShowingHelp: false,
      cursor: {
        row: 0,
        col: 0,
      },
      mode: "unknown", // unknown, standard, advanced
      tries: 0,
      board: parsePuzzle(payload.puzzle),
    };
  },
  focusLetter: (state, payload) => {
    state.cursor = payload;
  },
  keyPress: (state, payload) => {
    if (state.isValidating && state.mode === "advanced") {
      return;
    }

    if (state.gameState !== "running") {
      return;
    }

    if (state.mode === "unknown") {
      return;
    }

    const row = state.board[state.cursor.row];
    const col = row.letters[state.cursor.col];
    col.typedLetter = payload.toLowerCase();

    const totalRows = state.board.length;
    const totalLetters = row.letters.length;
    const isLastRow = state.cursor.row >= totalRows - 1;
    const isLastCol = state.cursor.col >= totalLetters - 1;

    if (!isLastRow && isLastCol) {
      state.cursor.row++;
      state.cursor.col = 0;
      state.isValidating = false;
    } else if (!isLastCol) {
      state.cursor.col++;
      state.isValidating = false;
    } else {
      state.isValidating = false;
    }
  },
  delete: (state) => {
    if (state.mode === "unknown") {
      return;
    }

    if (state.isValidating && state.mode === "advanced") {
      return;
    }

    if (state.gameState !== "running") {
      return;
    }
    const row = state.board[state.cursor.row];
    const col = row.letters[state.cursor.col];
    col.typedLetter = "";

    const isFirstRow = state.cursor.row === 0;
    const isFirstCol = state.cursor.col === 0;

    if (!isFirstRow && isFirstCol) {
      state.cursor.row--;
      state.cursor.col = state.board[state.cursor.row].letters.length - 1;
      state.isValidating = false;
    } else if (!isFirstCol) {
      state.cursor.col--;
      state.isValidating = false;
    } else {
      state.isValidating = false;
    }
  },
  submit: (state) => {
    if (state.mode === "unknown") {
      return;
    }

    if (state.gameState !== "running") {
      return;
    }

    if (state.mode === "advanced" && !isFilledSelector(state)) {
      return;
    }

    if (isEmptySelector(state)) {
      return;
    }

    const isValid = state.board.every((row) =>
      row.letters.every((col) => col.typedLetter === col.correctLetter)
    );

    if (isValid) {
      state.gameState = "won";
      return;
    }

    state.tries++;
    if (state.tries >= triesPerMode[state.mode]) {
      state.gameState = "lost";
      return;
    }

    state.isValidating = true;
  },
  tryAgainAdvanced: (state) => {
    state.isValidating = false;
    state.cursor.row = 0;
    state.cursor.col = 0;
    state.board.forEach((row) =>
      row.letters.forEach((col) => (col.typedLetter = ""))
    );
  },
  openHelp: (state) => {
    state.isShowingHelp = true;
  },
  closeHelp: (state) => {
    state.isShowingHelp = false;
  },
};

export const isFilledSelector = (state) => {
  return state.board.every((row) =>
    row.letters.every((col) => !!col.typedLetter)
  );
};

export const isEmptySelector = (state) => {
  return state.board.every((row) =>
    row.letters.every((col) => !col.typedLetter)
  );
};

const wrapped = (state, action) =>
  produce(state, (draft) => reducer[action.type]?.(draft, action.payload));

export default wrapped;
