import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import Board from "src/components/Board";
import Navbar from "src/components/Navbar";
import Help from "src/components/Help";
import { signAnonymousUser } from "src/contexts/AuthContext";
import { useLatestPuzzle } from "src/utils/api";
import StartModal from "src/components/StartModal";
import { parsePuzzle } from "src/utils/utils";
import gameReducer, { isEmptySelector, isFilledSelector } from "./gameReducer";

import "src/styles.scss";
import VirtualKeyboard from "src/components/VirtualKeyboard";
import GameEndModal from "src/components/GameEndModal";
import CheckAnswer from "src/components/CheckAnswer";
import { runConfetti } from "src/utils/confetti";

const useSignAnonymousUser = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    signAnonymousUser().then(() => setIsSignedIn(true));
  }, []);

  return isSignedIn;
};

const useKeyboard = ({ onDelete, onSubmit, onKeyPress }) => {
  const handleKeyboardRef = useRef();

  const handleKeyboard = (e) => {
    if (e.key === "Delete" || e.key === "Backspace") {
      onDelete();
    } else if (e.key === "Enter") {
      onSubmit();
    } else if (/^[a-zA-Z]$/.test(e.key)) {
      onKeyPress(e.key);
    }
  };

  handleKeyboardRef.current = handleKeyboard;

  const handleKeyboardMemo = useCallback((e) => {
    handleKeyboardRef.current(e);
  }, []);

  useEffect(() => {
    document.addEventListener("keyup", handleKeyboardMemo);

    return () => {
      document.removeEventListener("keyup", handleKeyboardMemo);
    };
  }, []);
};

const Game = ({ puzzle }) => {
  const [state, dispatch] = useReducer(gameReducer, {
    gameState: "running", // running, lost, won
    isValidating: false,
    isShowingHelp: false,
    cursor: {
      row: 0,
      col: 0,
    },
    mode: "unknown", // unknown, standard, advanced
    tries: 0,
    board: parsePuzzle(puzzle),
  });

  useEffect(() => {
    if (state.gameState === "won") {
      runConfetti();
    }
  }, [state.gameState]);

  const onDelete = () => {
    dispatch({ type: "delete" });
  };

  const onKeyPress = (key) => {
    dispatch({ type: "keyPress", payload: key });
  };

  const getSubmitMode = () => {
    if (state.isValidating && state.mode === "advanced") {
      return "tryAgainAdvanced";
    }

    if (state.gameState === "lost") {
      return "restart";
    }

    if (state.gameState === "won") {
      return "restart";
    }

    if (
      state.isValidating ||
      (state.mode === "advanced" && !isFilledSelector(state)) ||
      isEmptySelector(state)
    ) {
      return "disabled";
    }

    return "submit";
  };

  const onSubmit = () => {
    if (state.mode === "unknown") {
      return;
    }

    const mode = getSubmitMode();

    if (mode === "tryAgainAdvanced") {
      dispatch({ type: "tryAgainAdvanced" });
    }

    if (mode === "restart") {
      dispatch({ type: "restart", payload: { puzzle } });
    }

    if (mode === "submit") {
      dispatch({ type: "submit" });
    }
  };

  useKeyboard({
    onDelete,
    onSubmit,
    onKeyPress,
  });

  return (
    <div className="game">
      <Navbar
        tries={state.tries}
        mode={state.mode}
        onRestart={() => dispatch({ type: "restart", payload: { puzzle } })}
        onHelp={() => dispatch({ type: "openHelp" })}
      />
      {state.mode === "unknown" && (
        <StartModal
          onStandard={() => {
            dispatch({
              type: "startGame",
              payload: { mode: "standard", puzzle },
            });
          }}
          onAdvanced={() => {
            dispatch({
              type: "startGame",
              payload: { mode: "advanced", puzzle },
            });
          }}
        />
      )}
      <Board
        board={state.board}
        cursor={state.cursor}
        center={state.mode !== "unknown" && !state.isShowingHelp}
        isValidating={state.isValidating}
        onFocusLetter={(row, col) =>
          dispatch({ type: "focusLetter", payload: { row, col } })
        }
      />
      {state.mode !== "unknown" && !state.isShowingHelp && (
        <VirtualKeyboard
          submitMode={getSubmitMode()}
          onSubmit={onSubmit}
          onKeyPress={onKeyPress}
          onDelete={onDelete}
        />
      )}
      {state.mode !== "unknown" && !state.isShowingHelp && (
        <CheckAnswer submitMode={getSubmitMode()} onSubmit={onSubmit} />
      )}
      {state.isShowingHelp && (
        <Help onClose={() => dispatch({ type: "closeHelp" })} />
      )}
      {(state.gameState === "won" || state.gameState === "lost") && (
        <GameEndModal
          gameState={state.gameState}
          tries={state.tries}
          onRestart={() => {
            dispatch({ type: "restart", payload: { puzzle } });
          }}
        />
      )}
    </div>
  );
};

const SignedIn = () => {
  const puzzle = useLatestPuzzle();
  return puzzle && <Game puzzle={puzzle} />;
};

const GamePage = () => {
  const isSignedIn = useSignAnonymousUser();

  return isSignedIn && <SignedIn />;
};

export default GamePage;
