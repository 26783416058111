import React, { useRef } from "react";
import { useAuth } from "src/contexts/AuthContext";

import "src/styles.scss";
import "./LoginPage.scss";

const LoginPage = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();

  const handleSubmit = async () => {
    login(emailRef.current.value, passwordRef.current.value);
  };

  return (
    <div className="login-container">
      <div className="login-container-wrapper">
        <h1>LOGIN PAGE</h1>
        <input
          type="email"
          placeholder="Enter your email"
          ref={emailRef}
          required
        />
        <input
          type="password"
          placeholder="Enter your password"
          ref={passwordRef}
          required
        />
        <button onClick={handleSubmit}>Sign In</button>
      </div>
    </div>
  );
};

export default LoginPage;
