import { db } from "../firebaseConfig";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";

export const usePuzzles = () => {
  const [puzzles, setPuzzles] = useState(null);

  useEffect(() => {
    const wordsRef = collection(db, "words");
    getDocs(query(wordsRef, orderBy("schedule", "desc"), limit(100))).then(
      (data) => {
        const documents = data.docs.map((doc) => ({
          id: doc.id,
          words: doc.data().words,
          schedule: doc.data().schedule.toDate(),
        }));
        setPuzzles(documents);
      }
    );
  }, []);

  return { loading: !puzzles, puzzles };
};

export const useLatestPuzzle = () => {
  const [puzzle, setPuzzle] = useState(null);

  useEffect(() => {
    const wordsRef = collection(db, "words");
    getDocs(
      query(
        wordsRef,
        where("schedule", "<=", new Date()),
        orderBy("schedule", "desc"),
        limit(1)
      )
    ).then((data) => {
      setPuzzle(data.docs[0]?.data().words);
    });
  }, []);

  return puzzle;
};
