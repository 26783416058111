import React from "react";
import { ReactComponent as CloseIcon } from "src/assets/close_icon.svg";
import "./Help.scss";

const Help = ({ onClose }) => {
  return (
    <div className="window-overlay">
      <div className="window-wrapper">
        <div className="window-container">
          <div className="window-container-title">
            <div className="window-container-main-header">
              <h1>What is Towword?</h1>
            </div>
            <div className="window-container-close-icon" onClick={onClose}>
              <CloseIcon />
            </div>
          </div>
          <div className="window-container-wrapper" id="scrollbar-style">
            <div className="window-container-wrapper-scroll">
              <div className="window-container-wrapper-scroll-content">
                <p>
                  <b>Towwords</b> are buildings made of words that are
                  constructed from the top down.
                </p>
                Begin with the one- or two-letter word at the top floor and add
                one (or sometimes two) letters on each floor below until you
                complete the Towword by finding the Ground Floor Word. Good
                luck!
              </div>
              <div className="window-container-wrapper-scroll-header-with-padding">
                <h1>A few rules and hints</h1>
              </div>
              <div className="window-container-wrapper-scroll-content">
                <p>
                  All letters remain in the same sequence from floor to floor,
                  except:
                  <br />
                  <div className="window-container-wrapper-scroll-points">
                    1.&nbsp;&nbsp;any new letter added to the floor below will
                    be inserted before, after or between letters in sequence;
                    <br />
                    2.&nbsp;&nbsp;letters in colored window frames may change
                    positions or values on the floor below.
                  </div>
                </p>
                <div className="window-container-wrapper-scroll-header-without-padding">
                  <h1>Clues</h1>
                </div>
                <p>
                  For some words, clues are provided. Words with clues may be
                  dictionary or non-dictionary words such as familiar proper
                  nouns, abbreviations, foreign words, etc. which can be
                  indicated in the clue directly (e.g., the clue for “OUI” might
                  be “Yes in French”) or indirectly (e.g., “Pierre’s
                  affirmative”). Words without clues are English dictionary
                  words.
                </p>
                <div className="window-container-wrapper-scroll-header-without-padding">
                  <h1>I-Beams</h1>
                </div>
                <p>
                  Multiple words on a floor are always separated by a vertical
                  “I-Beam”. Thus the floor above "SINGAPORE" could read "SIN |
                  GAP | OR". The position of an I-Beam before or after a clue
                  indicates which word corresponds to the clue. For example, the
                  clue "It's original ||" would be a clue for "SIN". The clue "|
                  Mind the ____ |" would be a clue for "GAP". The absence of an
                  I-Beam indicates a single clue for multiple words. For
                  example, in a puzzle where the floor above "SHIPWRIGHT" reads
                  "SHIP | RIGHT", the clue "Fedex correctly?" is a clue for both
                  "SHIP" and "RIGHT".
                </p>
                <div className="window-container-wrapper-scroll-header-without-padding">
                  <h1>Window Frames</h1>
                </div>
                <p>
                  The building architect may use colored window frames to
                  indicate where letters are transposed or changed. For example,
                  if "R" is in an orange window frame and "A" is in a brown
                  frame in the word "GRAB", then the word can become "GARBO"
                  where the orange and brown frames switch positions below (and
                  "O" is the added letter). A dark blue window frame always
                  indicates a changed letter. For example, the word "FICTION”
                  might become "FRACTION", where the "I" has a red window frame
                  and the "A" is in a blue frame below (and "R" is the added
                  letter).
                </p>
                <div className="window-container-wrapper-scroll-header-without-padding">
                  <h1>Modes</h1>
                </div>
                <p>
                  In standard mode you:
                  <br />
                  <div className="window-container-wrapper-scroll-points">
                    1.&nbsp;&nbsp; may hit “enter” before filling in all letters
                    in the Towword;
                    <br />
                    2.&nbsp;&nbsp;will be told the floor on which any errors
                    lie;
                    <br />
                    3.&nbsp;&nbsp;will have five chances to construct the
                    Towword properly.
                  </div>
                </p>
                <p>
                  In advanced mode you:
                  <br />
                  <div className="window-container-wrapper-scroll-points">
                    1.&nbsp;&nbsp; must fill in all letters in the Towword
                    before hitting “enter”;
                    <br />
                    2.&nbsp;&nbsp;will not be told where any errors lie after
                    you hit “enter”;
                    <br />
                    3.&nbsp;&nbsp;will have three chances to construct the
                    Towword properly.
                    <br />
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
